import HomeBrutes from '../components/HomeBrutes';
import HomeHero from '../components/HomeHero';
import HomeRoadmap from '../components/HomeRoadmap';
import HomeTeam from '../components/HomeTeam';

function Home() {
  return (
    <div className='md:mt-5'>
      <HomeHero />
      <HomeRoadmap />
      <HomeBrutes />
      <HomeTeam />
    </div>
  );
}

export default Home;
