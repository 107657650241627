import Link from 'next/link';
import React from 'react';
import Spinner from './Spinner';

function Button({ children, type = 'button', onClick, href, target, loading, disabled, theme = 'primary', className, link }) {
  let themeClasses =
    'text-gray-800 text-bg bg-theme-1 border-theme-1 hover:bg-transparent hover:border-theme-1 transition duration-300 hover:text-theme-1';

  if (theme === 'outline') {
    themeClasses = 'text-theme-1 border-theme-1 hover:text-gray-800 text-theme-1 hover:bg-theme-1 hover:text-white';
  }

  if (theme === 'link') {
    themeClasses = 'border-transparent text-theme-1 hover:text-theme-1';
  }

  if (disabled) {
    themeClasses = 'opacity-50 cursor-not-allowed';
  }

  const classes = `inline-flex items-center px-4 py-2 border-2 text-sm font-bold rounded-md transition-all duration-200 tracking-wide text-center justify-center ${themeClasses} ${className}`;

  if (link && href) {
    return (
      <Link href={href} target={target}>
        <a className={classes}>{children}</a>
      </Link>
    );
  }

  if (href) {
    return (
      <a href={href} target={target} className={classes}>
        {children}
      </a>
    );
  }

  return (
    <button type={type} disabled={disabled} onClick={onClick} className={classes}>
      {loading ? <Spinner dimensionClasses='w-5 h-5' /> : <>{children}</>}
    </button>
  );
}

export default Button;
