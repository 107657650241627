import Button from './Button';
import config from '../config';

export default function HomeHero() {
  return (
    <div>
      <div className='relative'>
        <div className='absolute inset-x-0 bottom-0 bg-gray-800 h-1/2' />
        <div className='mx-auto max-w-7xl sm:px-6 lg:px-8'>
          <div className='relative shadow-xl sm:rounded-2xl sm:overflow-hidden'>
            <div className='absolute inset-0'>
              {/* <video className='absolute z-20 hidden object-cover w-full h-full lg:block' autoPlay={true} loop={true} muted={true}>
                <source src='/hero.mp4' type='video/mp4' />
              </video> */}
              <img className='z-10 object-cover w-full h-full' src='/banner.jpg' alt='YoungBruteGang' />
              <div className='absolute inset-0 bg-opacity-50 bg-theme-2 mix-blend-multiply' />
            </div>

            <div className='relative z-30 px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8'>
              <h1 className='text-4xl font-bold tracking-tight text-center sm:text-5xl lg:text-6xl'>
                <span className='block text-white'>199 YoungBrutes and 99 RebelBrutes</span>
                <span className='block text-theme-1'>on Algorand</span>
              </h1>
              <p className='max-w-lg mx-auto mt-6 text-xl text-center text-gray-200 md:text-2xl sm:max-w-3xl'>
                An NFT collection of 199 YoungBrutes and 99 RebelBrutes on the Algorand blockchain. All unique in their own way! Come join the gang
                today!
              </p>
              <div className='max-w-sm mx-auto mt-10 sm:max-w-none sm:flex sm:justify-center'>
                <div className='space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5'>
                  <Button href={config.nftExplorerYBG} className='px-8 py-3 text-base !block' target='_blank'>
                    Buy YoungBruteGang
                  </Button>
                  <Button href={config.nftExplorerRBG} className='px-8 py-3 text-base !block' target='_blank'>
                    Buy RebelBruteGang
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-gray-800'>
        <div className='px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8'>
          <p className='text-2xl text-center text-gray-500 max-w-[300px] md:max-w-none mx-auto'>Check out our links below to buy your Brute today!</p>

          <div className='grid grid-cols-1 gap-8 mt-12 lg:grid-cols-3'>
            <div className='flex justify-center col-span-1 md:col-span-2 lg:col-span-1'>
              <a href={config.nftExplorerYBG} target={'_blank'} rel='noreferrer'>
                <img className='h-10' src='/logos/nft-explorer.png' alt='NFT Explorer' />
              </a>
            </div>
            <div className='flex justify-center col-span-1 md:col-span-2 lg:col-span-1'>
              <a href={config.randGalleryYBG} target={'_blank'} rel='noreferrer' className='flex items-center space-x-4 text-2xl font-bold'>
                <img className='h-12' src='/logos/rand-gallery.png' alt='Rand Gallery' />
                <span className='opacity-50'>Rand Gallery</span>
              </a>
            </div>
            <div className='flex justify-center col-span-1 md:col-span-2 lg:col-span-1'>
              <a href={config.algoxnftYBG} target={'_blank'} rel='noreferrer'>
                <img className='h-12' src='/logos/algoxnft.png' alt='ALGOxNFT' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
