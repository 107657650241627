import config from '../config';

/* This example requires Tailwind CSS v2.0+ */
const people = [
  {
    name: 'Creator YBG',
    role: 'Creator',
    imageUrl: '/team/creator.png',
    twitter: 'https://twitter.com/YoungBruteGang',
  },
  {
    name: 'Tanner',
    role: 'Community Manager',
    imageUrl: '/team/tanner.png',
    twitter: 'https://twitter.com/Tanner113',
  },
  {
    name: 'Stuart',
    role: 'Brute Emissary',
    imageUrl: '/team/stuart.png',
    twitter: 'https://twitter.com/AlgoInvest2',
  },
  {
    name: 'Research',
    role: 'Brute Emissary',
    imageUrl: '/team/research.png',
    twitter: 'https://twitter.com/Resirchh',
  },
  {
    name: 'Steez',
    role: 'Brute Emissary',
    imageUrl: '/team/steez.png',
    twitter: 'https://twitter.com/SteezAlgo',
  },
  {
    name: 'SeaJoeMama',
    role: 'Brute Emissary',
    imageUrl: '/team/seajoemama.png',
    twitter: 'https://twitter.com/SeaJoeMama',
  },
];

export default function HomeTeam() {
  return (
    <div>
      <div className='px-4 py-12 mx-auto text-center max-w-7xl sm:px-6 lg:px-8 lg:py-24'>
        <div className='space-y-12'>
          <div className='space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl'>
            <h2 className='text-3xl font-bold tracking-tight sm:text-5xl'>The Brute Force</h2>
          </div>

          <ul role='list' className='mx-auto space-y-16 sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-16 sm:space-y-0 lg:max-w-5xl'>
            {people.map((person) => (
              <li key={person.name}>
                <div className='space-y-6'>
                  <img className='w-40 h-40 mx-auto rounded-full xl:w-56 xl:h-56' src={person.imageUrl} alt='' />
                  <div className='space-y-2'>
                    <div className='space-y-1 text-lg font-medium leading-6'>
                      <h3>{person.name}</h3>
                      <p className='text-theme-2'>{person.role}</p>
                    </div>

                    <ul role='list' className='flex justify-center space-x-5'>
                      {person.twitter !== '' && (
                        <li>
                          <a href={person.twitter} className='text-gray-400 hover:text-gray-500' target={'_blank'} rel='noreferrer'>
                            <span className='sr-only'>Twitter</span>
                            <svg className='w-5 h-5' aria-hidden='true' fill='currentColor' viewBox='0 0 20 20'>
                              <path d='M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84' />
                            </svg>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
