import React from 'react';
import Container from './Container';

function HomeRoadmap() {
  return (
    <div className='pt-20 pb-20 md:pt-32'>
      <Container>
        <h1 className='text-3xl font-extrabold tracking-tight text-center text-neutral-200 sm:text-5xl'>
          <span className='block xl:inline'>The Roadmap</span>
        </h1>

        <div className='mt-12'>
          <img src='/ybg-roadmap.jpeg' alt='Roadmap' className={'max-w-[750px] w-full mx-auto rounded-md'} />
        </div>
      </Container>
    </div>
  );
}

export default HomeRoadmap;
