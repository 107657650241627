import React from 'react';

function HomeBrutes() {
  return (
    <div className='py-12 md:py-32'>
      <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6'>
        <img src='/ybgs/1.png' alt='YBGS' className='col-span-1' />
        <img src='/ybgs/2.png' alt='YBGS' className='col-span-1' />
        <img src='/ybgs/3.png' alt='YBGS' className='col-span-1' />
        <img src='/ybgs/4.png' alt='YBGS' className='col-span-1' />
        <img src='/ybgs/5.png' alt='YBGS' className='col-span-1' />
        <img src='/ybgs/6.png' alt='YBGS' className='col-span-1' />
      </div>
      <div className='hidden grid-cols-2 lg:grid md:grid-cols-3 lg:grid-cols-6'>
        <img src='/ybgs/7.png' alt='YBGS' className='col-span-1' />
        <img src='/ybgs/8.png' alt='YBGS' className='col-span-1' />
        <img src='/ybgs/9.png' alt='YBGS' className='col-span-1' />
        <img src='/ybgs/10.png' alt='YBGS' className='col-span-1' />
        <img src='/ybgs/11.png' alt='YBGS' className='col-span-1' />
        <img src='/ybgs/12.png' alt='YBGS' className='col-span-1' />
      </div>
    </div>
  );
}

export default HomeBrutes;
